// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-cars-js": () => import("./../../../src/pages/cars.js" /* webpackChunkName: "component---src-pages-cars-js" */),
  "component---src-pages-carsxx-js": () => import("./../../../src/pages/carsxx.js" /* webpackChunkName: "component---src-pages-carsxx-js" */),
  "component---src-pages-clothes-js": () => import("./../../../src/pages/clothes.js" /* webpackChunkName: "component---src-pages-clothes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dates-js": () => import("./../../../src/pages/dates.js" /* webpackChunkName: "component---src-pages-dates-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-rice-js": () => import("./../../../src/pages/rice.js" /* webpackChunkName: "component---src-pages-rice-js" */),
  "component---src-pages-scrap-js": () => import("./../../../src/pages/scrap.js" /* webpackChunkName: "component---src-pages-scrap-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-shipping-containers-js": () => import("./../../../src/pages/shipping-containers.js" /* webpackChunkName: "component---src-pages-shipping-containers-js" */)
}

